// reducers.js
import { createSlice } from "@reduxjs/toolkit";

// export const fetchSearches = createAsyncThunk(
//   "fetchSearches",
//   async ({ loc, lang }) => {
//     try {
//       const response = await pythonSearchApi.post("filter-offer-count", {
//         location_id: loc,
//         language: lang,
//       });
//       console.log('Search :- ',response.data)
//       return response.data;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

const searchesSlice = createSlice({
  name: "Search",
  initialState: {
    // isLoading: false,
    cuisinesParmas: {},
    merchantParams: {},
  },
  reducers: {
    setCuisinesParams: (state, action) => {
      state.cuisinesParmas = action.payload;
    },
    setMerchantParams: (state, action) => {
      state.merchantParams = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(fetchSearches.pending, (state, action) => {
  //     state.isLoading = true;
  //   });
  //   builder.addCase(fetchSearches.fulfilled, (state, action) => {
  //     state.isLoading = false;
  //     state.data = action.payload;
  //   });
  //   builder.addCase(fetchSearches.rejected, (state, action) => {
  //     console.log("Error", action.payload);
  //     state.isError = true;
  //   });
  // },
});

export const { setCuisinesParams, setMerchantParams } = searchesSlice.actions;

export default searchesSlice.reducer;

export const lang = "(en|ar|ru)"
export const city = "(dubai-n-emirates|abu-dhabi-al-ain|qatar|bahrain|oman|jeddah|riyadh|eastern-province|egypt|kuwait|singapore)"


// import { useTranslation } from "react-i18next";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// function baseurl_city() {
//     const { lang } = useParams();
//     const { t } = useTranslation();
//     const { i18n } = useTranslation();
  
//     let ch_translation = (l) => {
//       window.location.href = window.location.href.replace(
//         "/" + lang + "-",
//         "/" + l + "-"
//       );
//     };

// export const baseurl_lang = "(en|ar|ru)"
// export const baseurl_city = "(en|ar|ru)"
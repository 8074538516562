// store.js
import { configureStore } from "@reduxjs/toolkit";
import languageReducers from "./languageReducers";
import HomeSectionsReducers from "./slices/HomeSections";
import CategoriesReducers from "./slices/Categories";
import LocationsReducers from "./slices/Locations";
import TrendingReducers from "./slices/Trending";
import AuthReducers from "./slices/Auth";
import PlansReducers from "./slices/Plans";
import PlanDetailsReducers from "./slices/PlanDetails";
import SearchReducers from "./slices/Search";
import TestimonialsReducers from "./slices/Testimonials";


const store = configureStore({
  reducer: {
    language: languageReducers,
    locations: LocationsReducers,
    homeSections: HomeSectionsReducers,
    categories: CategoriesReducers,
    trending: TrendingReducers,
    auth: AuthReducers,
    plans: PlansReducers,
    search: SearchReducers,
    testimonials: TestimonialsReducers,
    planDetails: PlanDetailsReducers,
  },
  
});

export default store;
